import validateNumberRange from 'ethos-design-system/src/validators/NumberInputValidator'
import { ageNearestBirthDate } from 'lib/@getethos/utils/ageNearestBirthDate'
import { setAndGetUserUuid } from 'lib/FeaturesAndExperiments/setAndGetUserUUID'
import { bool, object, string } from 'yup'

export type PrimaryAddress = {
  street1: string
  street2?: string
  postalCode: string
  locality: string
  region: string
}

export enum LeadSource {
  CP_WITHOUT_QUOTE = 'CP Without Quote',
  CP_WITH_SALES = 'CP With Sales',
  FE_WIDGET_WITH_PHONE = 'FE Widget With Phone',
  CMS_IUL_LANDING_PAGE = 'cms_iul_landing_page',
}

export interface LeadFormInputFields {
  firstName: string
  lastName: string
  phone: string
  email: string
}

interface DataForSalesForce {
  First_Name: string
  Last_Name: string
  External_Identifier: string
  Lead_Source: string
  Address?: string
  City?: string
  Zip?: string
  State?: string
  Phone?: string
  Applicant_Age?: number
  Email?: string
}

export const birthDateMask = [
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const phoneMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const citizenValidator = (citizen: string): string => {
  return citizen === 'Other'
    ? 'Unfortunately Ethos can only sell policies to US citizens or permanent residents at this time.'
    : ''
}

const minimumEligibleAge = 20
const maximumEligibleAge = 85

export const validationSchema = (isTreatment2: boolean) =>
  object().shape({
    firstName: string()
      .required('Please provide first name')
      .min(2, 'First name must be at least 2 characters long')
      .max(50, 'First name cannot exceed 50 characters')
      .matches(/^[a-zA-Z0-9\s.-]*$/, 'First name contains invalid characters'),
    lastName: string()
      .required('Please provide last name')
      .min(2, 'Last name must be at least 2 characters long')
      .max(50, 'Last name cannot exceed 50 characters')
      .matches(/^[a-zA-Z0-9\s.-]*$/, 'Last name contains invalid characters'),
    phone: string()
      .required('Please provide phone')
      .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Invalid phone number'),
    email: string()
      .required('Please provide email')
      .email('Please provide a valid email address'),
  })

interface SalesForceLeadData {
  firstName?: string
  lastName?: string
  gender?: string
  birthDate?: string
  citizen?: string
  phone?: string
  primaryAddress?: Partial<PrimaryAddress>
  isManualForm?: boolean
  region?: string
  email?: string
}

export const prepareDataForSalesForce = (
  values: SalesForceLeadData,
  leadSource: LeadSource
) => {
  const userId = setAndGetUserUuid()

  // Initialize data object with required properties
  const data: DataForSalesForce = {
    First_Name: '⠀',
    Last_Name: '⠀',
    External_Identifier: userId,
    Lead_Source: leadSource,
  }

  if (values.primaryAddress) {
    const { locality, postalCode, region, street1, street2 } =
      values.primaryAddress

    const address = `${street1}${
      street2 ? `, ${street2}` : ''
    }, ${locality}, ${region} ${postalCode}`

    data.Address = address
    data.City = locality
    data.Zip = postalCode
    data.State = region
  }
  if (values.region) {
    data.State = values.region
  }
  if (values.firstName) {
    data.First_Name = values.firstName
  }
  if (values.lastName) {
    data.Last_Name = values.lastName
  }
  if (values.phone) {
    data.Phone = values.phone
  }
  if (values.birthDate) {
    data.Applicant_Age =
      typeof values.birthDate === 'string'
        ? ageNearestBirthDate({ birthDate: values.birthDate })
        : values.birthDate
  }
  if (values.email) {
    data.Email = values.email
  }

  return data
}
