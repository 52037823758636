import { useCallback, useRef } from 'react'

import { useModuleContext } from '@/contexts/ModuleContext'
import { StorageKeys, Store } from '@/services/storage'
import { estimateWidgetAnalytics } from 'lib/@getethos/analytics/analyticsEvents'
import { createLead } from 'lib/@getethos/api/createLead'

import { useEstimateQuotesContext } from '@/components/EstimateWidget/contexts/EstimateQuotesContext'
import { useEstimateWidgetContext } from '@/components/EstimateWidget/contexts/EstimateWidgetContext'

import LeadForm from './LeadForm'
import {
  LeadFormInputFields,
  LeadSource,
  prepareDataForSalesForce,
} from './utils'

interface Props {
  onSuccess?: () => void
}

export const LeadFormWrapper = ({ onSuccess }: Props) => {
  const touchedFieldsRef = useRef<Array<string>>([])

  const store = Store.getInstance('localStorage')

  const wasLeadFormSubmitted = store?.getItem(StorageKeys.LeadFormSubmitted)

  const {
    userDataControls: { setUserData },
    widgetProgressControls: { setWidgetProgress },
  } = useEstimateWidgetContext()
  const { handleGoToMainApp } = useEstimateQuotesContext()
  const {
    moduleControls: { setHideModules },
    footerControls: { hideFooter },
  } = useModuleContext()

  const handleCPToSalesSubmit = async () => {
    estimateWidgetAnalytics.continueToSales({
      properties: {
        triedToEditAnswers: false,
      },
    })
  }

  const handleSubmit = async (values: LeadFormInputFields) => {
    const formType = LeadSource.CMS_IUL_LANDING_PAGE

    const data = prepareDataForSalesForce(values, formType)

    setUserData({ ...values })

    estimateWidgetAnalytics.leadFormSubmitted({
      properties: {
        formType,
        triedToEditAnswers: false,
      },
    })
    try {
      if (wasLeadFormSubmitted) {
        console.warn('Lead form already submitted')
        return
      }
      await createLead({ data })
      store.setItem(StorageKeys.LeadFormSubmitted, true)
      if (onSuccess) onSuccess()
    } catch (error) {
      console.error('Error submitting form', error) // TODO: we need DD logs
    } finally {
      await handleCPToSalesSubmit()
    }
  }

  const handleOnBlur = useCallback((field: string) => {
    if (touchedFieldsRef.current.includes(field)) return
    estimateWidgetAnalytics.userInteractedWithField({
      properties: {
        field,
        context: 'lead-form',
      },
    })
    touchedFieldsRef.current.push(field)
  }, [])

  return (
    <LeadForm
      onSubmit={handleSubmit}
      title="Guaranteed coverage*"
      subtitle="This is coverage lasts your whole life. You can't be turned down due to your health, and your rate will never go up!"
      ctaLabel="CONTINUE"
      handleOnBlur={handleOnBlur}
    />
  )
}
