import { useState } from 'react'

import { Button, Dropdown, Input } from '@getethos/ethos-design-system-v2'
import { yupResolver } from '@hookform/resolvers/yup'
import classnames from 'classnames'
import { estimateWidgetAnalytics } from 'lib/@getethos/analytics/analyticsEvents'
import { apiRoutes } from 'lib/@getethos/urls/apiRoutes'
import {
  Controller,
  FieldErrors,
  Resolver,
  SubmitHandler,
  useForm,
} from 'react-hook-form'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import { v4 as uuidv4 } from 'uuid'

import { useEstimateWidgetContext } from '@/components/EstimateWidget/contexts/EstimateWidgetContext'

import {
  CITIZENSHIP_OPTIONS,
  GENDER_OPTIONS,
} from '../FinalExpenseInfoForm/utils/constants'
import styles from './LeadForm.module.scss'
import Consent from './components/Consent'
import Overview from './components/Overview'
import {
  LeadFormInputFields,
  birthDateMask,
  phoneMask,
  validationSchema,
} from './utils'

interface LeadFormInterface {
  title: string
  subtitle: string
  onSubmit: SubmitHandler<LeadFormInputFields>
  ctaLabel: string
  handleOnBlur?: (field: string) => void
}

const LeadForm = ({
  title,
  subtitle,
  ctaLabel,
  onSubmit,
  handleOnBlur,
}: LeadFormInterface) => {
  const [formId, setFormId] = useState(uuidv4())
  const {
    userDataControls: { userData },
  } = useEstimateWidgetContext()

  const isTreatment2 = true

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
    clearErrors,
    setError,
  } = useForm<LeadFormInputFields>({
    resolver: yupResolver(validationSchema(isTreatment2)) as Resolver<
      LeadFormInputFields,
      any
    >,
    defaultValues: {
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      phone: undefined,
    },
    mode: 'onSubmit',
  })

  const onFormRerender = () => setFormId(uuidv4())

  const handleInvalidSubmit = (errors: FieldErrors<LeadFormInputFields>) => {
    const currentValues = getValues()
    estimateWidgetAnalytics.continueToSalesError({
      properties: {
        errorMessages: errors,
        currentValues,
        formType: 'Lead',
      },
    })
  }

  const validateAndSubmitForm = async () => {
    try {
      await apiRoutes.validatePhoneTwilio(getValues().phone)

      await onSubmit(getValues())
    } catch (error) {
      setError('phone', {
        type: 'manual',
        message:
          'Phone validation failed. Please ensure the phone number is correct and try again.',
      })
    }
  }

  return (
    <form
      key={formId}
      onSubmit={handleSubmit(validateAndSubmitForm, handleInvalidSubmit)}
      className="lg-max-w-full order-1 w-full flex-1 custom-md:order-2 custom-md:min-w-[380px] custom-md:max-w-[600px]"
    >
      <div className="grid grid-cols-1 gap-x-3 gap-y-6 custom-md:grid-cols-2">
        <div className={styles.inputWrapper}>
          <Controller
            control={control}
            name="firstName"
            render={({
              field: { ref, ...fieldRest },
              fieldState: { error },
            }) => (
              <Input
                {...fieldRest}
                label="First name"
                onBlur={() => handleOnBlur?.('firstName')}
                inputSize="sm"
                errorMessage={error?.message}
              />
            )}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Controller
            control={control}
            name="lastName"
            render={({
              field: { ref, ...fieldRest },
              fieldState: { error },
            }) => (
              <Input
                {...fieldRest}
                onBlur={() => handleOnBlur?.('lastName')}
                label="Last name"
                inputSize="sm"
                errorMessage={error?.message}
              />
            )}
          />
        </div>

        <div className={styles.inputWrapper}>
          <Controller
            control={control}
            name="phone"
            render={({
              field: { ref, ...fieldRest },
              fieldState: { error },
            }) => (
              <Input
                {...fieldRest}
                label="Phone"
                onBlur={() => handleOnBlur?.('phone')}
                placeholder="(555) 555 - 5555"
                mask={phoneMask}
                inputSize="sm"
                errorMessage={error?.message}
              />
            )}
          />
        </div>

        <div className={styles.inputWrapper}>
          <Controller
            control={control}
            name="email"
            render={({
              field: { ref, ...fieldRest },
              fieldState: { error },
            }) => (
              <Input
                {...fieldRest}
                label="Email"
                onBlur={() => handleOnBlur?.('email')}
                inputSize="sm"
                errorMessage={error?.message}
              />
            )}
          />
        </div>
      </div>
      <Button
        buttonTitle={ctaLabel}
        size="md"
        variant="primary"
        className={styles.button}
        leadingIcon="arrowForward"
        isLoading={isSubmitting}
        disabled={isSubmitting}
        alignment="center"
      />
      <Consent buttonTitle={ctaLabel} />
    </form>
  )
}

export default LeadForm
